<template>
    <CourierCompanyBase :loader_prop="loader_prop">
        <div class="add_courier_banner" v-if= "add_courier_screen == true">
            <div class="comman_banner">
                <h2>הוספת שליח</h2>
            </div>
            <div class="container">
                <div class="singup_page">
                    <form @submit="add" class="singup_form">
                        <p class="error_text" >{{errs}}</p>
                        <div class="form-group">
                            <label>שם השליח</label>
                            <input type="text" class="form-control" v-model="name">
                        </div>
                        <div class="form-group">
                            <label>מספר טלפון</label>
                            <input type="text" class="form-control" v-model="phone_number">
                        </div>
                        <div class="form-group">
                            <label>סיסמה</label>
                            <input type="password" class="form-control" v-model="password">
                        </div>
                        <div class="form-group">
                            <label>כתובת מייל</label>
                            <input type="text" class="form-control" v-model="email">
                        </div>
                        <input type="submit" class="comman_anchor" value="שמירה">
                    </form>
                </div>
            </div>
        </div>
        <div class="package_section thankyou_section" v-if = "thankyou == true">
            <div class="container">
                <div class="table_wrap">
                    <div class="cell_wrap">
                        <div class="empty_package">
                            <img src="images/thankyou_page_box.png" alt="Thank You">
                            <p>תודה, השליח נשמר :)</p>
                            <router-link :to="{path : '/courierCompany/courier_company_courier'}" class="comman_anchor">למסך הבית </router-link>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </CourierCompanyBase> 
</template>
<script>
    import BackendModel from "../../models/BackendModel";
    import CourierCompanyBase from "./CourierCompanyBase";
    import('./couriercompany/css/style.css')
    export default {
      name: 'CourierCompanyAddCourier',
      components: {
        CourierCompanyBase
      },
      data() {
        return {
            name : '',
            phone_number : '',
            password : '',
            email : '',
            errs:'',
            thankyou : false,
            add_courier_screen : true,
            loader_prop : false,
        }
      },

      async mounted() {
         this.errs = ''
      },
      
      methods : {

        async add(e) {
            e.preventDefault()
            let phone_number = this.phone_number.replaceAll('-','')
            const backendModel = new BackendModel()
            let res = await backendModel.courierCompany_Request('/Api/service/backoffice/courier_company_add_courier', {"name" : this.name , "phone_number" : phone_number, "password" : this.password ,"email" : this.email} );
             if (res.data.res == "NOK" ){
                this.errs = 'אופס, שכחת משהו'
            }
            if(res.data.res == "NOK" && res.data.err =="DUPLICATE"){
                this.errs = 'השליח רשום'
            }
            if(res.data.res == "OK" ){
                this.thankyou = true
                this.add_courier_screen = false
                this.loader_prop = false
            // await this.$router.push({ path: '/courierCompany/courierCompany_courier' })  
            }
        }

      }
    }

</script>

<style>
@import 'couriercompany/css/style.css';
</style>